import * as React from 'react';
import global from '../css/Global.module.css';
import Features from '../css/Features.module.css';
import { NavLink, BrowserRouter as Router } from 'react-router-dom';

function WideNonHomeTopHeroUnit(props) { 
    return (
    <div className={[global.firstHeroUnit, global.wideFirstHeroUnit].join(' ')}>
      <div className={global.firstHeroUnitContainerLeft}>
        <div className={global.firstHeroUnitContent}>
          <h3>{props.titleTag}</h3>
          <h1>{props.title}</h1>
          <p className={Features.introParagraph}>{props.titleIntroParagraph}</p>
          <div className={global.getStarted}><a href="/">Get Started</a></div>
        </div>
      </div>
      <div className={global.firstHeroUnitContainerRight}>
        <div className={global.topHeroUnitImage}><img src={props.titleImg}></img></div>
      </div>
    </div>
    );
}

export default WideNonHomeTopHeroUnit;