import Header from '../../components/Header.js';
import NonHomeTopHeroUnit from '../../components/NonHomeTopHeroUnit.js';
import LeftFacingHeroBlock from '../../components/LeftFacingHeroBlock.js';
import RightFacingHeroBlock from '../../components/RightFacingHeroBlock.js';
import global from '../../css/Global.module.css';
import Footer from '../../components/Footer.js';
import topHeroUnitImage from '../../bin/images/shifts_topHeroUnit.png';
import heroBlockImage1 from '../../bin/images/shifts_setups.png';
import heroBlockImage2 from '../../bin/images/shifts_leaders.png';
import heroBlockImage3 from '../../bin/images/shifts_checklistsAndTasks.png';
import heroBlockImage4 from '../../bin/images/shifts_shiftsDashboard.png';

function Shifts() {
  return (
    <div className={global.contentBody}> 
      <NonHomeTopHeroUnit
        imgSrc='/bin/images/vsbl_background.png'
        titleTag='SHIFTS'
        title='Everything, Everyday, One Screen'
        titleIntroParagraph='Literally. Make running shifts easier and more consistent, delivering tasks directly to your team, giving everyone autonomy to do their job.'
        titleImg={topHeroUnitImage}
      />

      <LeftFacingHeroBlock 
        imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
        featureTag="SETUPS" 
        featureTitle="Every Setup for Every Day" 
        featureBlurb='Build out setup times and positions with staffing numbers - specify to each day of the week. Your leaders will know what they need to build automatically every day.'
        titleImg={heroBlockImage1}
      />

      <RightFacingHeroBlock 
        imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
        featureTag="LEADERS" 
        featureTitle="Enhancing Clarity and Communication" 
        featureBlurb='Organize positions into groups like "Drive Thru" or "Front Counter". Adjust staffing numbers, set specific times, and enjoy a readability upgrade.'
        titleImg={heroBlockImage2}
      />

      <LeftFacingHeroBlock 
        imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
        featureTag="CHECKLISTS &#38; TASKS" 
        featureTitle="Your Pathway to Efficiency" 
        featureBlurb='Set the due times, start times, and days of the week for checklists & tasks that need to be completed. Through Setups, they are then automatically assigned to employees by their position.'
        titleImg={heroBlockImage3}
      />

        <RightFacingHeroBlock 
          imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
          featureTag="SHIFTS DASHBOARD" 
          featureTitle="Your Command Center" 
          featureBlurb='Running shifts is difficult and complicated. When everything is in one place, Shift Leaders are better equipped to face the callouts, machine breakdowns, and wrapped-around drive thru lines.'
          titleImg={heroBlockImage4}
        />
    </div>
  );
}

export default Shifts;


