import Header from '../../components/Header.js';
import NonHomeTopHeroUnit from '../../components/NonHomeTopHeroUnit.js';
import LeftFacingHeroBlock from '../../components/LeftFacingHeroBlock.js';
import RightFacingHeroBlock from '../../components/RightFacingHeroBlock.js';
import global from '../../css/Global.module.css';
import Footer from '../../components/Footer.js';
import topHeroUnitImage from '../../bin/images/Team_and_Scoreboard_Hero.png';
import heroBlockImage5 from '../../bin/images/teamFeed_interact.png';
import heroBlockImage1 from '../../bin/images/Teams_Section.jpg';
import heroBlockImage2 from '../../bin/images/teamFeed_broadcasts.png';
import heroBlockImage3 from '../../bin/images/teamFeed_birthdaysAndAnniversaries.png';
import heroBlockImage4 from '../../bin/images/teamFeed_scoreboard.png';

function TeamAndScoreboard() {
  return (
    <div className={global.contentBody}> 
      <NonHomeTopHeroUnit 
        imgSrc='/bin/images/vsbl_background.png'
        titleTag='TEAM &amp; SCOREBOARD'
        title='Keep track of your Team'
        titleIntroParagraph='Team Feed shows you what’s going on throughout the day, but also allows team members to interact together, celebrating wins and staying in-the-know'
        titleImg={topHeroUnitImage} 
      />

      <LeftFacingHeroBlock 
        imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
        featureTag="TEAMS" 
        featureTitle="Easiest way to assign Tasks" 
        featureBlurb='Create Teams for a quick and easy way to assign tasks to a specific group, eliminating the need to designate specific individuals. This streamlined approach ensures responsibilities are clearly distributed and managed within the team.'
        titleImg={heroBlockImage1}
      />

      <RightFacingHeroBlock 
        imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
        featureTag="INTERACT" 
        featureTitle="Likes &amp; Comments for the Team" 
        featureBlurb='On every item in the feed, you can like and add comments to ask questions or give virtual high fives. Use this as an opportunity to track who has watched that training video you posted.'
        titleImg={heroBlockImage5}
      />

      <LeftFacingHeroBlock 
        imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
        featureTag="BROADCASTS" 
        featureTitle="Important Announcements with Photos &amp; Links" 
        featureBlurb='Keep your team in-the-know with what’s important. Broadcasts send a push notification and can be sent to customized groups by area and role. Attach a photo of your newest hire or add a link for how to make the new milkshake rolling out today.'
        titleImg={heroBlockImage2}
      />

      <RightFacingHeroBlock 
        imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
        featureTag="BIRTHDAYS &amp; ANNIVERSARIES" 
        featureTitle="Celebrate with your Team" 
        featureBlurb='What you celebrate, you cultivate. Make your people feel seen and your culture will thrive.'
        titleImg={heroBlockImage3}
      />

        <LeftFacingHeroBlock 
          imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
          featureTag="SCOREBOARD" 
          featureTitle="Everything in the Restaurant in One Place" 
          featureBlurb='Get a 30,000 ft view of your store. Scoreboard is that place, whether you’re at the restaurant, at home, or on the beach somewhere. Know what’s been completed or not, as well as who shouldn’t receive their meal discount today.'
          titleImg={heroBlockImage4}
        />
    </div>
  );
}

export default TeamAndScoreboard;


