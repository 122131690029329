import Header from '../../components/Header.js';
import NonHomeTopHeroUnit from '../../components/NonHomeTopHeroUnit.js';
import LeftFacingHeroBlock from '../../components/LeftFacingHeroBlock.js';
import RightFacingHeroBlock from '../../components/RightFacingHeroBlock.js';
import VideoRightFacingHeroBlock from '../../components/videoRightFacingHeroBlock.js';
import global from '../../css/Global.module.css';
import Footer from '../../components/Footer.js';
import topHeroUnitImage from '../../bin/images/rewards_topHeroUnit.png';
import heroBlockImage1 from '../../bin/images/rewards_gamification.png';
import heroBlockImage2 from '../../bin/images/rewards_positiveReinforcement.png';
import heroBlockImage3 from '../../bin/images/rewards_gifts.png';
import gameController from '../../bin/images/rewards_controller.png';
import chart from '../../bin/images/rewards_chart.png';
import smileyFace from '../../bin/images/rewards_smileFace.png';
import plusSign from '../../bin/images/rewards_plusSign.png';
import equals from '../../bin/images/rewards_equals.png';

function Rewards() {
  return (
    <div className={global.contentBody}> 
      <NonHomeTopHeroUnit 
        imgSrc='/bin/images/vsbl_background.png'
        titleTag='REWARDS'
        title='Increased Engagement through Rewards'
        titleIntroParagraph='When people are excited about their job, they bring their best self to the table'
        titleImg={topHeroUnitImage} 
      />

      <div className={[global.monolith].join(' ')}>
        <div className={[global.monolithInner, global.rewardsContainer].join(' ')}>
          <div className={global.rewardsContainerTop}>
            <div className={global.rewardsTopTag}>ENGAGEMENT</div>
            <div className={global.rewardsTopText}>Want to know our Formula?</div>
          </div>
          <div className={global.rewardsContainerBottom}>
            <div className={[global.rewardsGamification, global.rewardsBlock].join(' ')}>
              <div className={global.rewardsBlockTop}><img src={gameController} /></div>
              <div className={global.rewardsBlockBottom}>Gamification</div>
            </div>
            <div className={global.rewardsMiddleBlock}><img src={plusSign} /></div>
            <div className={[global.rewardspositiveReinforcement, global.rewardsBlock].join(' ')}>
              <div className={global.rewardsBlockTop}><img src={chart} /></div>
              <div className={global.rewardsBlockBottom}>Positive Reinforcement</div>
            </div>
            <div className={global.rewardsMiddleBlock}><img src={equals} /></div>
            <div className={[global.rewardsEngagedEmployees, global.rewardsBlock].join(' ')}>
              <div className={global.rewardsBlockTop}><img src={smileyFace} /></div>
              <div className={global.rewardsBlockBottom}>Engaged Employees</div>
            </div>
          </div>
        </div>
      </div>

      <LeftFacingHeroBlock 
        imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
        featureTag="GAMIFICATION" 
        featureTitle="Complete Tasks, Get Points" 
        featureBlurb='Keep your team members engaged and motivated with gamification. Watch as they eagerly complete daily tasks and checklists to earn points, unlocking a world of rewards created by you.'
        titleImg={heroBlockImage1}
      />

      <RightFacingHeroBlock 
        imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
        featureTag="POSITIVE REINFORCEMENT" 
        featureTitle="Recognize your Team" 
        featureBlurb='Employees can recognize co-workers for going above and beyond, fostering a culture of encouragement and achievement. Recognitions and Top Performer selections earn points, but also give public praise in the Team Feed.'
        titleImg={heroBlockImage2}
        class={global.positiveReinforcement}
      />

      <LeftFacingHeroBlock 
        imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
        featureTag="GIFTS" 
        featureTitle="Send Points to Each Other" 
        featureBlurb='When someone covers a shift, send them 20 points as a thank you. Try pooling your points together to get bigger Rewards.'
        titleImg={heroBlockImage3}
      />

      <VideoRightFacingHeroBlock 
        imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
        featureTag="REWARDS"
        featureTitle="Have fun with it!" 
        featureBlurb='Rewards your employees can track and redeem themselves, from break food to new uniforms to smashing a pie in your GM’s face.'
      />
    </div>
  );
}

export default Rewards;


