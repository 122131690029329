import Header from '../../components/Header.js';
import NonHomeTopHeroUnit from '../../components/NonHomeTopHeroUnit.js';
import LeftFacingHeroBlock from '../../components/LeftFacingHeroBlock.js';
import WideRightFacingHeroBlock from '../../components/WideRightFacingHeroBlock.js';
import global from '../../css/Global.module.css';
import Footer from '../../components/Footer.js';
import topHeroUnitImage from '../../bin/images/waste_topHeroUnit.png';
import heroBlockImage1 from '../../bin/images/waste_tracking.png';
import heroBlockImage2 from '../../bin/images/waste_submitting.png';
import heroBlockImage3 from '../../bin/images/waste_reporting.png';
import wasteClock from '../../bin/images/waste_clock.png';
import wasteMoneyBag from '../../bin/images/waste_moneyBag.png';
import wastePotPlant from '../../bin/images/waste_potPlant.png';

function Waste() { 
  return (
    <div className={global.contentBody}> 
      <NonHomeTopHeroUnit 
        imgSrc='/bin/images/vsbl_background.png'
        titleTag='WASTE'
        title='Keep Track of Waste and Donations'
        titleIntroParagraph='Realtime capture provides insights for better decision making.'
        titleImg={topHeroUnitImage}
      />

    <div className={[global.monolith].join(' ')}>
        <div className={[global.monolithInner, global.wasteContainer].join(' ')}>
            <div className={global.featureWasteTitle}>Use Waste, Get Results</div>
            <div className={global.wastesubText}>Discover the impact that waste tracking can make on your business</div>
            <div className={global.wasteBoxes}>
              <div className={global.wasteBox}>
                <div className={global.wasteBoxLeft}><img src={wastePotPlant} /></div>
                <div className={global.wasteBoxRight}>
                  <div className={global.wasteTextTop}>50%</div>
                  <div className={global.wasteTextBottom}>Less food waste within the first month*</div>
                </div>
              </div>
              <div className={global.wasteBox}>
                <div className={global.wasteBoxLeft}><img src={wasteClock} /></div>
                <div className={global.wasteBoxRight}>
                  <div className={global.wasteTextTop}>10</div>
                  <div className={global.wasteTextBottom}>Labor hours saved per month*</div>
                </div>
              </div>
              <div className={global.wasteBox}>
                <div className={global.wasteBoxLeft}><img src={wasteMoneyBag} /></div>
                <div className={global.wasteBoxRight}>
                  <div className={global.wasteTextTop}>$64,000</div>
                  <div className={global.wasteTextBottom}>In tax deductions recorded*</div>
                </div>
              </div>
            </div>
        </div>
      </div>

      <LeftFacingHeroBlock 
        featureTag="TRACKING" 
        featureTitle="Capture At the Source" 
        featureBlurb='By placing tablets strategically where waste occurs, Team Members track waste when it happens. Realtime capture means better results.'
        titleImg={heroBlockImage1}
      />

      <WideRightFacingHeroBlock 
        featureTag="REPORTING" 
        featureTitle="Know Your Opportunities" 
        featureBlurb='Our waste reporting feature provides insight to where you should direct your efforts to trim food costs. Use this in leadership meetings to keep priorities in focus.'
        titleImg={heroBlockImage3}
      />

      <LeftFacingHeroBlock 
        featureTag="SUBMITTING" 
        featureTitle="Tasks Assigned to Shift Leaders" 
        featureBlurb='No more end-of-night report runs. Task assignments to shift leaders ensures all waste is captured, from open to close, across every restraunt area. Our algorithmic aproach leaves no waste unaccounted for.'
        titleImg={heroBlockImage2}
      />
    </div>
  );
}

export default Waste;


