import Header from '../../components/Header.js';
import NonHomeTopHeroUnit from '../../components/NonHomeTopHeroUnit.js';
import LeftFacingHeroBlock from '../../components/LeftFacingHeroBlock.js';
import RightFacingHeroBlock from '../../components/RightFacingHeroBlock.js';
import global from '../../css/Global.module.css';
import Footer from '../../components/Footer.js';
import topHeroUnitImage from '../../bin/images/vendors_topHeroUnit.png';
import heroBlockImage1 from '../../bin/images/vendors_contact.png';
import heroBlockImage2 from '../../bin/images/vendors_useCase.png';

function Vendors() {
  return (
    <div className={global.contentBody}>
      <NonHomeTopHeroUnit 
        imgSrc='/bin/images/vsbl_background.png'
        titleTag='VENDORS'
        title='Vendor Contact Directory'
        titleIntroParagraph='Quick access to vendors for your entire team'
        titleImg={topHeroUnitImage} 
      />

      <LeftFacingHeroBlock 
        imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
        featureTag="CONTACT" 
        featureTitle="Call, Text, and Email" 
        featureBlurb='Tap the icons to quickly connect. Add notes on vendors to keep your team informed, such as “Can only be reached Monday-Friday 8am-5pm”.'
        titleImg={heroBlockImage1}
      />

      <RightFacingHeroBlock 
        imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
        featureTag="USE CASE" 
        featureTitle="Borrowing from Nearby Stores" 
        featureBlurb='When you run out of product, how many people know who to call? With Vendors, add nearby stores so your team knows who to contact when you’re in need.'
        titleImg={heroBlockImage2}
      />
    </div>
  );
}

export default Vendors;


