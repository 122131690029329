import * as React from 'react';
import global from '../css/Global.module.css';
import Features from '../css/Features.module.css';
import { NavLink, BrowserRouter as Router } from 'react-router-dom';
import PathConstants from '../routes/pathConstants.js';

function NonHomeTopHeroUnit(props) { 
    return (
    <div className={global.firstHeroUnit}>
      <div className={global.firstHeroUnitContainerLeft}>
        <div className={global.firstHeroUnitContent}>
          <h3>{props.titleTag}</h3>
          <h1>{props.title}</h1>
          <p className={Features.introParagraph}>{props.titleIntroParagraph}</p>
          <div className={global.getStarted}><a href={PathConstants.SignUp}>Get Started</a></div>
        </div>
      </div>
      <div className={global.firstHeroUnitContainerRight}>
        <div className={global.topHeroUnitImage}><img src={props.titleImg}></img></div>
      </div>
    </div>
    );
}

export default NonHomeTopHeroUnit;