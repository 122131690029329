import * as React from 'react';
import global from '../css/Global.module.css';
import setupTemplates from '../bin/images/setupTemplates.png';
import { NavLink, BrowserRouter as Router } from 'react-router-dom';

function WideRightFacingHeroBlock(props) { 
    return (
        <div className={[global.monolith, global.rightImgMonolith, global.wideMonolith].join(' ')}>
            <div className={[global.monolithInner, global.rightImgMonolithInner].join(' ')}>
                <div className={global.featureOverview}>
                    <div className={global.featureTag}>{props.featureTag}</div>
                    <div className={global.featureTitle}>{props.featureTitle}</div>
                    <p className={global.featureBlurb}>{props.featureBlurb}</p>
                </div>
                <div className={global.imageContainer}><img className={global.imgSetupTemplate} src={props.titleImg}></img></div>
            </div>
        </div>
    );
}

export default WideRightFacingHeroBlock;