import NonHomeTopHeroUnit from '../../components/NonHomeTopHeroUnit.js';
import LeftFacingHeroBlock from '../../components/LeftFacingHeroBlock.js';
import RightFacingHeroBlock from '../../components/RightFacingHeroBlock.js';
import global from '../../css/Global.module.css';
import topHeroUnitImage from '../../bin/images/Training_Train.png';
import heroBlockImage1 from '../../bin/images/Training_Assign.png';
import heroBlockImage2 from '../../bin/images/Training_Train.png';
import heroBlockImage3 from '../../bin/images/training_Manage.png';

function Training() { 
  return (
    <div className={global.contentBody}>
      <NonHomeTopHeroUnit 
        imgSrc='/bin/images/vsbl_background.png'
        titleTag='Training'
        title='Train smarter, not harder'
        titleIntroParagraph='Restaurant life is hard enough, training your people should not be. Quickly assign training plans, monitor progress, and provide feedback—all with ease.'
        titleImg={topHeroUnitImage}
      />

      <LeftFacingHeroBlock 
        featureTag="ASSIGN" 
        featureTitle="Send plans to your Team" 
        featureBlurb='Add courses to training plans for easy assignment. Schedule trainers and dates in advance, or train on-the-fly.'
        titleImg={heroBlockImage1}
      />

      <RightFacingHeroBlock 
        featureTag="TRAIN" 
        featureTitle="Employees know what they’re doing" 
        featureBlurb='Trainees see their courses on their home screens, knowing exactly what to learn next. Trainers track who they’re training and what to cover. Detailed feedback and ratings highlight progress and areas for improvement, boosting training effectiveness.'
        titleImg={heroBlockImage2}
      />

      <LeftFacingHeroBlock 
        featureTag="MANAGE" 
        featureTitle="Manage all your training from one screen" 
        featureBlurb='Easily manage all your training from one screen. Use filters to see only what you need, and tap on plans to view courses and trainer notes. Track progress, review past training, and ensure everyone’s training is on track.'
        titleImg={heroBlockImage3}
      />
    </div>
  );
}

export default Training;


