import NonHomeTopHeroUnit from '../../components/NonHomeTopHeroUnit.js';
import LeftFacingHeroBlock from '../../components/LeftFacingHeroBlock.js';
import RightFacingHeroBlock from '../../components/RightFacingHeroBlock.js';
import global from '../../css/Global.module.css';
import topHeroUnitImage from '../../bin/images/homeScreen_topHeroUnit.png';
import heroBlockImage1 from '../../bin/images/homeScreen_myJobForToday.png';
import heroBlockImage2 from '../../bin/images/homeScreen_adoption.png';
import heroBlockImage3 from '../../bin/images/homeScreen_rewardPoints.png';
import heroBlockImage4 from '../../bin/images/homeScreen_checkIn.png';

function HomeScreen() {
  return (
    <div className={global.contentBody}>
      <NonHomeTopHeroUnit 
        imgSrc='/bin/images/vsbl_background.png'
        titleTag='HOME SCREEN'
        title='Everything I Need to Do Today'
        titleIntroParagraph='Equip every person on your team with the tools and information needed to do their job well'
        titleImg={topHeroUnitImage} 
      />

      <LeftFacingHeroBlock 
        imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
        featureTag="MY JOB FOR TODAY" 
        featureTitle="Positions &amp; To-Dos" 
        featureBlurb='Restaurant work typically includes being in positions and doing tasks while in those positions. Each person receives both for the day here.'
        titleImg={heroBlockImage1}
      />

      <RightFacingHeroBlock 
        imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
        featureTag="ADOPTION" 
        featureTitle="Training Team Members Made Easy" 
        featureBlurb='Team members don’t need to train on VSBL. Just download the app, login, and each day find what you need.'
        titleImg={heroBlockImage2}
      />

      <LeftFacingHeroBlock 
        imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
        featureTag="REWARD POINTS" 
        featureTitle="Track &amp; Redeem" 
        featureBlurb='See what you’ve earned points for, as well as how far you are from earning that next reward.'
        titleImg={heroBlockImage3}
      />

        <RightFacingHeroBlock 
          imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
          featureTag="CHECK IN" 
          featureTitle="Set It and Forget It" 
          featureBlurb='It’s easy to forget things you’ve delegated, but with Check In, you don’t have to. Everything you assign to someone else will show up on your home screen the day it’s due. It’s like your own personal assistant.'
          titleImg={heroBlockImage4}
        />
    </div>
  );
}

export default HomeScreen;