import * as React from 'react';
import global from '../css/Global.module.css';
import Features from '../css/Features.module.css';
import { NavLink, BrowserRouter as Router } from 'react-router-dom';
import PathConstants from '../routes/pathConstants.js';

function HomeTopHeroUnit(props) {
    return (
    <div className={global.homeFirstHeroUnit}>
        <div className={global.homeFirstHeroUnitContent}>
          <h1>{props.line1}</h1>
          <h1>{props.line2}</h1>
          <h1>{props.line3}</h1>
          <p className={Features.homeIntroParagraph}>{props.titleIntroParagraph}</p>
          <div className={global.homeGetStarted}><a href={PathConstants.SignUp}>Get Started</a></div>
        </div>
    </div>
    );
}

export default HomeTopHeroUnit;