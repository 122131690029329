import NonHomeTopHeroUnit from '../../components/NonHomeTopHeroUnit.js';
import LeftFacingHeroBlock from '../../components/LeftFacingHeroBlock.js';
import RightFacingHeroBlock from '../../components/RightFacingHeroBlock.js';
import global from '../../css/Global.module.css';
import topHeroUnitImage from '../../bin/images/accountability_topHeroUnit.png';
import heroBlockImage1 from '../../bin/images/accountability_documentation.png';
import heroBlockImage2 from '../../bin/images/accountability_automated.png';
import heroBlockImage3 from '../../bin/images/accountability_betterCulture.png';

function Accountability() { 
  return (
    <div className={global.contentBody}>
      <NonHomeTopHeroUnit 
        imgSrc='/bin/images/vsbl_background.png'
        titleTag='ACCOUNTABILITY'
        title='Coaching &amp; Discipline Made Easy'
        titleIntroParagraph='Know when to have important conversations and gain peace of mind with better documentation'
        titleImg={topHeroUnitImage}
      />

      <LeftFacingHeroBlock 
        featureTag="DOCUMENTATION" 
        featureTitle="Better Records, Better Support, More Protection" 
        featureBlurb='By tracking broken rules and documenting conversations, it’s easy to see how someone’s doing and areas to focus on. Handle unemployment claims faster with quick access to reports.'
        titleImg={heroBlockImage1}
      />

      <RightFacingHeroBlock 
        featureTag="AUTOMATED" 
        featureTitle="Know When to Have Important Conversations" 
        featureBlurb='With VSBL, all leaders can report broken rules, but you can assign important conversations to high-level leaders. When a verbal warning or suspension needs to happen, we’ll notify the leaders you set responsible. No one falls through the cracks.'
        titleImg={heroBlockImage2}
      />

      <LeftFacingHeroBlock 
        featureTag="BETTER CULTURE" 
        featureTitle="Grace + Enforcement" 
        featureBlurb='Embrace accountability without compromising flexibility. Our system allows you to choose between enforcing or deferring consequences, providing grace when needed. After all, there are grey areas; we’re just trying to help reduce them.'
        titleImg={heroBlockImage3}
      />
    </div>
  );
}

export default Accountability;


