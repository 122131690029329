import { Outlet } from "react-router-dom"
import Header from "./Header.js"
import Footer from "./Footer.js"

export default function Layout(props) {
    return (
        <div>
            <a id="top"></a>
            <Header />
            <div className="App">            
                <Outlet />
            </div>
            <Footer />
        </div>
    )
}